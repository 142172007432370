import React from 'react'
import './MyServices.css'
import { IoIosPlay } from "react-icons/io";
import FD from "../../Assets/fd.png";
import { Link } from 'react-router-dom';
import Forex from "../../Assets/iconforex.png";
import { SiBitcoin } from "react-icons/si";
import { MdLockPerson } from "react-icons/md";
import SIP from "../../Assets/sip.png";
import { MdSavings } from "react-icons/md";

const MyServices = () => {
  return (
    <div className='big-container'>
        <div className="headerServices">
            <h1>SERVICES WE OFFER</h1>
            <div className="greenLine"></div>
            <p>Strategic Investments, Sustainable Growth.</p>
        </div>
        <div className="servicesDivs">
            <div className="ServicesBox">
            <Link to="/crypto">
                <div className="iconSerDiv">
                <SiBitcoin size={80} color='#fff'/>
                </div>
                <div className="headingsServ">
                    <h2>Cyrpto Trading</h2>
                    <p className='smallme'>Decentralize Your Future Wealth Now! </p>
                </div>
                
                <div className="getStartedDiv">
                    <button>Get Started </button>
                    {/* <IoIosPlay color='#279147' /> */}
                </div>
                </Link>
            </div>
            <div className="ServicesBox">
            <Link to="/forex">
                <div className="iconSerDiv">
                <img src={Forex} alt="" />
                </div>
                <div className="headingsServ">
                    <h2>Forex Trade</h2>
                    <p className='smallme'>Forex Fortunes Await You.</p>
                </div>
                <div className="getStartedDiv">
                    <button>Get Started </button>
                    {/* <IoIosPlay color='#279147' /> */}
                </div>
                </Link>
            </div>
            <div className="ServicesBox">
            <Link to="/fixedDeposit">
                <div className="iconSerDiv">
                <MdLockPerson size={80} color='#fff'/>
                </div>
                <div className="headingsServ">
                    <h2>Fixed Deposit</h2>
                    <p className='smallme'>Secure Growth, Future Flourish, Invest.</p>
                </div>
                <div className="getStartedDiv">
                    <button>Get Started </button>
                    {/* <IoIosPlay color='#279147' /> */}
                </div>
                </Link>
            </div>
            <div className="ServicesBox">
            <Link to='/SIP'>
                <div className="iconSerDiv">
                <img src={SIP} alt="" />
                </div>
                <div className="headingsServ">
                    <h2>SIP</h2>
                    <p className='smallme'>Sip, Invest, Thrive, Repeat</p>
                </div>
                <div className="getStartedDiv">
                    <button>Get Started </button>
                    {/* <IoIosPlay color='#279147' /> */}
                </div>
                </Link>
            </div>
            <div className="ServicesBoxSoon">
                <div className="iconSerDiv">
                    <img src={FD} alt="" />
                </div>
                <div className="headingsServ">
                    <h2>COMING SOON</h2>
                    <p>Lorem ipsum dolor sit amet </p>
                </div>
                <div className="getStartedDiv">
                    <button>Get Started </button>
                    <IoIosPlay color='#279147' />
                </div>
            </div>
            <div className="ServicesBoxSoon">
                <div className="iconSerDiv">
                    <img src={FD} alt="" />
                </div>
                <div className="headingsServ">
                    <h2>Online Trading</h2>
                    <p>Lorem ipsum dolor sit amet </p>
                </div>
                <div className="getStartedDiv">
                    <button>Get Started </button>
                    <IoIosPlay color='#279147' />
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default MyServices
