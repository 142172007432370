import React,{useRef, useState} from 'react'
import './About.css'
import CG from '../../Assets/CG.png'
import FW from '../../Assets/FW.png'
import MP from '../../Assets/MP.png'
import TT from '../../Assets/TT.png'
import Logo from '../../Assets/SeedifyLogo.png'
import { Fade } from 'react-slideshow-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import tagline from "../../Assets/taglineslide.png";
import vision from "../../Assets/visionslide.png";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import { Autoplay, EffectCube, Pagination, Navigation, EffectFade } from 'swiper/modules';
import 'react-slideshow-image/dist/styles.css'
const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000'
  }
  
  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '400px'
  }
  const slideImages = [
    {
      url: '../../Assets/taglineslide.png',
      caption: 'Slide 1'
    },
    {
      url: 'https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80',
      caption: 'Slide 2'
    },
    {
      url: 'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
      caption: 'Slide 3'
    },
  ];
  
const About = () => {
  return (
    <div className='big-container'>
      <div className="headerAbout">
        <h1>WHY CHOOSE US?</h1>
        <div className="greenLine"></div>
        <p>Where Quality Meets Commitment. </p>
      </div>
      <div className="AboutSection">
        <div className="LeftAbout">
            {/* <img src={Logo} alt="" /> */}
            <div className="slide-container">
              <Swiper
                effect={'cube'}
                grabCursor={true}
                cubeEffect={{
                  shadow: true,
                  slideShadows: true,
                  shadowOffset: 20,
                  shadowScale: 0.94,
                }}
                // pagination={true}
                autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              loop={true}
              

                modules={[Navigation, Autoplay, EffectCube, Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src={tagline} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={vision} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={tagline}  />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={vision} />
                </SwiperSlide>
                {/* <SwiperSlide>
                  <img src={tagline} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={vision}  /> 
                </SwiperSlide>*/}
              </Swiper>
            </div>
        </div>
        <div className="RightAbout">
            <div className="aboutDiv">
                <div className="headingAbout">
                    <img src={CG} alt="" />
                    <div className="headings">
                        <h2>Capital Guaranted</h2>
                        <h6>Secure Investment with Guarantee</h6>
                    </div>
                </div>
                <div className="contentAbout">
                    <p>Explore secure investments with our capital guaranteed options, ensuring protection and growth for your financial portfolio.</p>
                </div>
            </div>
            <div className="aboutDiv">
                <div className="headingAbout">
                    <img src={FW} alt="" />
                    <div className="headings">
                        <h2>Flexible Withdrawal</h2>
                        <h6>Adaptable Cash Access</h6>
                    </div>
                </div>
                <div className="contentAbout">
                    <p>Effortlessly access your funds with our Flexible Withdrawal feature, providing convenience and control over your investment portfolio.</p>
                </div>
            </div>
            <div className="aboutDiv">
                <div className="headingAbout">
                    <img src={MP} alt="" />
                    <div className="headings">
                        <h2>Monthly Payout</h2>
                        <h6>Monthly Distribution Schedule</h6>
                    </div>
                </div>
                <div className="contentAbout">
                    <p>Experience consistent financial rewards with our Monthly Payouts, providing steady returns and dependable income for investors.</p>
                </div>
            </div>
            <div className="aboutDiv">
                <div className="headingAbout">
                    <img src={TT} alt="" />
                    <div className="headings">
                        <h2>Transparent Trade</h2>
                        <h6>Clear Investment Opportunities Platform</h6>
                    </div>
                </div>
                <div className="contentAbout">
                    <p>Ethical investments, clear insights, and real-time data for confident decision-making thereby empowering your financial journey.</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default About
