import React from "react";
import { useWindowSize } from "react-use";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import "./Team.css";
import "swiper/css";
import SIP from "../../Assets/sip.png";
const Team = () => {
  const { width: windowWidth } = useWindowSize();
  return (
    <div className="team-container">
    <div className="headerteam">
      <h1>OUR TEAM</h1>
      <div className="greenLine"></div>
      <p>Empowering Together, Achieving Forever.</p>
    </div>

        <Swiper
        spaceBetween={50}
        slidesPerView={4}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        modules={[Pagination, Navigation]}
        pagination={{
                type: "progressbar",
              }}
              breakpoints={{
                0: {
                  spaceBetween: 0,
                  slidesPerView: 1,
                },
                768: {
                  spaceBetween: 60,
                  slidesPerView: 2,
                },
                992: {
                  spaceBetween: 50,
                  slidesPerView: 3,
                },
                1280: {
                  spaceBetween: 70,
                  slidesPerView: 4,
                },
                1536: {
                  spaceBetween: 80,
                  slidesPerView: 4,
                },
                1600: {
                  spaceBetween: 98,
                  slidesPerView: 3,
                },
                1920: {
                  spaceBetween: 99,
                  slidesPerView: 3,
                },
                2250: {
                  spaceBetween: 120,
                  slidesPerView: 3,
                },
              }}
      >
        <SwiperSlide>
          <div className="cardChar">
            <div className="handles">
              <ul>
                <li><a href="#"><FaLinkedin className="iconSocials"/></a></li>
                <li><a href="#"><FaInstagram className="iconSocials"/></a></li>
                <li><a href="#"><FaXTwitter className="iconSocials"/></a></li>
              </ul>
            </div>
            <div className="profile">
              <div className="imageDiv">
                <img src={SIP} alt="" />
              </div>
              <div className="infoProfile">
                <h2>Kamaal Ansari</h2>
                <p>Director</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="cardChar">
            <div className="handles">
              <ul>
                <li><a href="#"><FaLinkedin className="iconSocials"/></a></li>
                <li><a href="#"><FaInstagram className="iconSocials"/></a></li>
                <li><a href="#"><FaXTwitter className="iconSocials"/></a></li>
              </ul>
            </div>
            <div className="profile">
              <div className="imageDiv">
                <img src={SIP} alt="" />
              </div>
              <div className="infoProfile">
                <h2>Ganeshprasad Gupta</h2>
                <p>Associate</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="cardChar">
            <div className="handles">
              <ul>
                <li><a href="#"><FaLinkedin className="iconSocials"/></a></li>
                <li><a href="#"><FaInstagram className="iconSocials"/></a></li>
                <li><a href="#"><FaXTwitter className="iconSocials"/></a></li>
              </ul>
            </div>
            <div className="profile">
              <div className="imageDiv">
                <img src={SIP} alt="" />
              </div>
              <div className="infoProfile">
                <h2>Madhulika Gupta</h2>
                <p>Associate</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="cardChar">
            <div className="handles">
              <ul>
                <li><a href="#"><FaLinkedin className="iconSocials"/></a></li>
                <li><a href="#"><FaInstagram className="iconSocials"/></a></li>
                <li><a href="#"><FaXTwitter className="iconSocials"/></a></li>
              </ul>
            </div>
            <div className="profile">
              <div className="imageDiv">
                <img src={SIP} alt="" />
              </div>
              <div className="infoProfile">
                <h2>Zeebran Patel</h2>
                <p>Associate</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        
      </Swiper>
  
     
    </div>
  );
};

export default Team;
