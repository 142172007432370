import React, { useState, useRef, useEffect } from "react";
import "./CryptoPage.css";
import "./index.less";
import "../Hero/locomotive-scroll.css";
import LocomotiveScroll from "locomotive-scroll";
import NavBar from "../NavBar/NavBar";
import LunaBot1 from "../../Assets/lunabot1.png";
import LunaBot2 from "../../Assets/lunabot2.png";
import LunaBot3 from "../../Assets/lunabot3.png";
import LunaBot4 from "../../Assets/lunabot4.png";
import Appex from "../CircularProgressBar/Appex.jsx";
import Footer from "../Footer/Footer.jsx";
import Slider, { Range } from "rc-slider";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { BsCashCoin } from "react-icons/bs";

import { BiSolidTimeFive } from "react-icons/bi";

import { GiProfit } from "react-icons/gi";

import { FaPercentage } from "react-icons/fa";
import bot1 from "../../Assets/bot1.png";
import bot2 from "../../Assets/bot2.png";
import bot3 from "../../Assets/bot3.png";
import bot4 from "../../Assets/bot4.png";
import "rc-slider/assets/index.css";
const imagesLoaded = require("imagesloaded");

const CryptoPage = () => {
  const scrollRef = useRef(null);
  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");

    if (scrollContainer) {
      const scroll = new LocomotiveScroll({
        el: scrollContainer,
        smooth: true,
        getSpeed: true,
        getDirection: true,
        multiplier: 0.4, // Adjust the scroll speed here
      });

      scroll.update();

      imagesLoaded(scrollContainer, { background: true }, function () {
        scroll.update();
      });

      const resizeObserver = new ResizeObserver((entries) => {
        scroll.update();
      });

      resizeObserver.observe(document.body);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // State to store selected LunaBot and input values
  const [selectedBot, setSelectedBot] = useState(null);
  const [principal, setPrincipal] = useState("");
  const [duration, setDuration] = useState(0);
  const [output, setOutput] = useState("0");
const[profitRate, setProfitRate] = useState("")

  // Function to handle calculation based on selected bot
  const calculateProfit = () => {
    let minPrincipal;
    let maxProfitPercentage;

    switch (selectedBot) {
      case LunaBot1:
        minPrincipal = 2500;
        maxProfitPercentage = 0.02;
        setProfitRate("2%");
        break;
      case LunaBot2:
        minPrincipal = 6000;
        maxProfitPercentage = 0.03;
        setProfitRate("3%");
        break;
      case LunaBot3:
        minPrincipal = 12000;
        maxProfitPercentage = 0.04;
        setProfitRate("4%");
        break;
      case LunaBot4:
        minPrincipal = 30000;
        maxProfitPercentage = 0.05;
        setProfitRate("5%");
        break;
      default:
        return; // If no bot selected, do nothing
    }

    // Convert input values to numbers
    const principalAmount = parseFloat(principal);
    const durationMonths = parseFloat(duration);

    // Validate input
    if (isNaN(principalAmount) || isNaN(durationMonths)) {
      setOutput("Please enter valid input.");
      return;
    }

    if (principalAmount < minPrincipal) {
      alert(`Minimum principal for ${selectedBot} is ${minPrincipal}.`);
      return;
    }

    // Calculate profit
    const profit = principalAmount * maxProfitPercentage;
    setOutput(`$${profit.toFixed(2)}`);
  };

  const marks = {
    0: "24",
    0: "0",
    6: "6M",
    9: "9M",
    12: "12M",
    18: "18M",
    24: {
      style: {
        color: "red",
      },
      label: "24M",
    },
  };

  return (
    <>
      <NavBar />
      <div className="serviceDivBig" data-scroll-container ref={scrollRef}>
        <div className="content-container" data-scroll-section>
          <div className="headingContent">
            <h1>Welcome to Our Crypto Investment Opportunity</h1>
          </div>
          <div className="contentDiv">
            <span className="headerContentDiv">About</span>
            <p className="content">
              At SEEDIFY, we understand the growing interest in cryptocurrency
              investments. As a forward-thinking investment company, we strive
              to provide our clients with diverse opportunities to grow their
              wealth. With the rise of cryptocurrencies like Bitcoin, Ethereum,
              and many others, we recognize the potential for significant
              returns in this rapidly evolving market.
              <br />
              Explore our Crypto Knowledge Hub, where you can gain insights into
              the world of cryptocurrency investing. Whether you're a seasoned
              investor or new to the crypto space, our resources will help you
              navigate this exciting asset class. From understanding blockchain
              technology to analyzing market trends, we're here to empower you
              with the knowledge you need to make informed investment decisions.
            </p>
          </div>
          <div className="contentDiv">
            <span className="headerContentDiv">
              Transparent Portfolio Management
            </span>
            <p className="content">
              At SEEDIFY, transparency is at the core of our investment
              approach. When you invest with us, your funds will be allocated to
              a carefully curated portfolio of assets, including
              cryptocurrencies. We believe in full transparency regarding our
              investment strategies, performance, and fees. Through regular
              reporting and open communication, we aim to build trust and
              confidence with our clients.
            </p>
          </div>
          <h2 className="chooseheader">WHY CHOOSE US?</h2>
          <div className="whychooseusDiv">
            <div className="contentDiv">
              <span className="headerContentDiv">Experienced Team</span>
              <p className="content">
                Our team of investment professionals has extensive experience in
                managing portfolios across various asset classes, including
                cryptocurrencies.
              </p>
            </div>
            <div className="contentDiv">
              <span className="headerContentDiv">Diversified Portfolio</span>
              <p className="content">
                We employ a diversified investment strategy to minimize risk and
                maximize returns for our clients.
              </p>
            </div>
            <div className="contentDiv">
              <span className="headerContentDiv">Transparent Reporting</span>
              <p className="content">
                You'll have access to detailed reports on your investment
                portfolio, including performance metrics and asset allocation.
              </p>
            </div>
            <div className="contentDiv">
              <span className="headerContentDiv">Client-Centric Approach</span>
              <p className="content">
                Your financial goals and interests are our top priority. We
                tailor our investment solutions to meet your specific needs and
                objectives.
              </p>
            </div>
          </div>
          {/* <p className="contentEnd">Ready to explore the potential of cryptocurrency investing with SEEDIFY <br/>Get started today by reaching out to our team. <br/>Whether you're looking to diversify your portfolio or capitalize on the growth of the crypto market, we're here to support you every step of the way.</p> */}
        </div>
        <div className="headerBot" data-scroll-section>
          <h3>Our Crypto Bots</h3>
        </div>
        <div className="botContainer" data-scroll-section>
          <img src={bot1} alt="" />
          <img src={bot2} alt="" />
          <img src={bot3} alt="" />
          <img src={bot4} alt="" />
        </div>
        <div className="single-container" data-scroll-section>
          <div className="inputForm">
            <div className="lunabots">
              <p>Choose your Plan</p>
            
              <div className="bots">
              <div className="listingbots">
              <img
                  src={LunaBot1}
                  alt=""
                  className="lunabot"
                  data-tip data-tooltip-id="lunabot1Tip"
                  onClick={() => setSelectedBot(LunaBot1)}
                />
                
                <ReactTooltip id="lunabot1Tip" place="bottom" effect="solid">
                Minimum Investment - $2,500/-
                </ReactTooltip>
                <img
                  src={LunaBot2}
                  alt=""
                  className="lunabot"
                  data-tip data-tooltip-id="lunabot2Tip"
                  onClick={() => setSelectedBot(LunaBot2)}
                />
                <ReactTooltip id="lunabot2Tip" place="bottom" effect="solid">
                Minimum Investment - $6,000/-
                </ReactTooltip>
                <img
                  src={LunaBot3}
                  alt=""
                  className="lunabot"
                  data-tip data-tooltip-id="lunabot3Tip"
                  onClick={() => setSelectedBot(LunaBot3)}
                />
                <ReactTooltip id="lunabot3Tip" place="bottom" effect="solid">
                Minimum Investment - $12,000/-
                </ReactTooltip>
                <img
                  src={LunaBot4}
                  alt=""
                  className="lunabot"
                  data-tip data-tooltip-id="lunabot4Tip"
                  onClick={() => setSelectedBot(LunaBot4)}
                />
                <ReactTooltip id="lunabot4Tip" place="bottom" effect="solid">
                Minimum Investment - $30,000/-
                </ReactTooltip>
              </div>
                
                <div className="cryptoCalcBox">
                  <div className="Box">
                    <label>Enter your principal amount</label>
                    <input
                      type="text"
                      className="PrincipalCrypto"
                      placeholder="Enter the amount in Dollars"
                      value={principal}
                      onChange={(e) => setPrincipal(e.target.value)}
                    />
                  </div>
                  <div className="Box">
                    <label>Choose your preferred duration</label>
                    <Slider
                      className="sliderrange"
                      min={0}
                      step={20}
                      max={24}
                      marks={marks}
                      onChange={setDuration}
                      value={duration}
                    />
                  </div>
                  <div className="Box">
                    <button className="profitBtn" onClick={calculateProfit}>
                      Calculate Returns
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {output}
          <div className="outputHeader">
          <div className="greenLine"></div>
              <h2>Your Returns</h2>
              <div className="greenLine"></div>
            </div>
          <div className="outputWindow">
            
          <div className="outputBox">
            <span className="headerOutput">
            <BsCashCoin size={20}/>
              Principal Amount
            </span>
            <h1>${principal}</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <BiSolidTimeFive size={20}/>
              Duration
            </span>
            <h1>{duration} Months</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <GiProfit size={20}/>
              Profit per Month
            </span>
            <h1>{output}</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <FaPercentage size={20}/>
              Profit Percentage
            </span>
            <h1>Upto {profitRate}</h1>
          </div>
          </div>
        </div>
        <div data-scroll-section id="indigenous">
          <Footer />
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default CryptoPage;
