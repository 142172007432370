import React, { useState, useRef, useEffect } from "react";
import "./FixedDepositPage.css";
import "../Hero/locomotive-scroll.css";
import LocomotiveScroll from "locomotive-scroll";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import Slider, { Range } from "rc-slider";

import { BsCashCoin } from "react-icons/bs";

import { BiSolidTimeFive } from "react-icons/bi";

import { GiProfit } from "react-icons/gi";

import { FaPercentage } from "react-icons/fa";
import "rc-slider/assets/index.css";
const imagesLoaded = require("imagesloaded");


const FixedDepositPage = () => {
  const scrollRef = useRef(null);
  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");

    if (scrollContainer) {
      const scroll = new LocomotiveScroll({
        el: scrollContainer,
        smooth: true,
        getSpeed: true,
        getDirection: true,
        multiplier: 0.4, // Adjust the scroll speed here
      });

      scroll.update();

      imagesLoaded(scrollContainer, { background: true }, function () {
        scroll.update();
      });

      const resizeObserver = new ResizeObserver((entries) => {
        scroll.update();
      });

      resizeObserver.observe(document.body);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const [principal, setPrincipal] = useState("");
  const [duration, setDuration] = useState("");
  const [profit, setProfit] = useState(0);
  const[profitRate, setProfitRate] = useState("")

  const handlePrincipalChange = (e) => {
    setPrincipal(e.target.value);
  };

  const calculateProfit = () => {
    const minInvestment = 10000;
    let rateOfInterest = 0;

    if (principal < minInvestment) {
      alert("Minimum investment amount is $10000");
      return;
    }

    switch (duration) {
      case 6:
        rateOfInterest = 0.25;
        setProfitRate("25%");
        break;
      case 9:
        rateOfInterest = 0.4;
        setProfitRate("40%");
        break;
      case 12:
        rateOfInterest = 0.6;
        setProfitRate("60%");
        break;
      default:
        alert("Please select a valid duration");
        return;
    }

    const interest = principal * rateOfInterest;
    setProfit(interest);
  };
  const marks = {
  
    0: "0",
    6: "6M",
    9: "9M",
    
    
    12: {
      style: {
        color: "red",
      },
      label: "12M",
    },
  };


  return (
    <>
      <NavBar />
      <div className="serviceDivBig" data-scroll-container ref={scrollRef}>
        <div className="content-container" data-scroll-section>
          <div className="headingContent">
            <h1>Welcome to Our Fixed Deposit Investment Opportunity</h1>
          </div>
          <div className="contentDiv">
            <span className="headerContentDiv">About</span>
            <p className="content">
              At SEEDIFY, we understand the importance of stable and secure
              investment options. That's why we're pleased to offer our clients
              the opportunity to invest in Fixed Deposits (FDs), a trusted and
              reliable way to grow your savings.
            </p>
          </div>
          <div className="contentDiv">
            <span className="headerContentDiv">
              Fixed Deposit Knowledge Hub
            </span>
            <p className="content">
              Click here to explore our Fixed Deposit Knowledge Hub, where you
              can discover valuable information about Fixed Deposits. Whether
              you're new to investing or seeking to optimize your portfolio, our
              resources will guide you through the ins and outs of Fixed
              Deposits. From understanding interest rates to comparing FD
              options, we're here to empower you with the knowledge you need to
              make informed investment decisions.
            </p>
          </div>
          <div className="contentDiv">
            <span className="headerContentDiv">
              Transparent Portfolio Management
            </span>
            <p className="content">
              When you invest with SEEDIFY, transparency is our priority. Your
              funds will be invested in a diversified portfolio of assets,
              including Fixed Deposits. We believe in providing our clients with
              full visibility into our investment strategies, performance, and
              fees. Through transparent reporting and open communication, we aim
              to earn your trust and confidence as your investment partner.
            </p>
          </div>
          <h2 className="chooseheader">WHY CHOOSE US?</h2>
          <div className="whychooseusDiv">
            <div className="contentDiv">
              <span className="headerContentDiv">Stability & Security</span>
              <p className="content">
                Fixed Deposits offer a stable and secure investment option,
                providing guaranteed returns over a predetermined period.
              </p>
            </div>
            <div className="contentDiv">
              <span className="headerContentDiv">Transparent Reporting</span>
              <p className="content">
                You'll receive regular reports detailing the performance of your
                Fixed Deposit investment, ensuring transparency and
                accountability.
              </p>
            </div>
            <div className="contentDiv">
              <span className="headerContentDiv">
                Competitive Interest Rates
              </span>
              <p className="content">
                We strive to offer competitive interest rates on our Fixed
                Deposits, ensuring that your savings work harder for you.
              </p>
            </div>
            <div className="contentDiv">
              <span className="headerContentDiv">Flexible Tenures</span>
              <p className="content">
                Choose from a range of flexible tenure options to suit your
                financial goals and preferences.
              </p>
            </div>
          </div>
          {/* <p className="contentEnd">Ready to explore the potential of cryptocurrency investing with SEEDIFY <br/>Get started today by reaching out to our team. <br/>Whether you're looking to diversify your portfolio or capitalize on the growth of the crypto market, we're here to support you every step of the way.</p> */}
        </div>
        <div className="single-container" data-scroll-section>
        <p className="headercalc">CALCULATE YOUR RETURNS</p>
          <div className="inputForm">
            <div className="inputboxs">
            <div className="Box">
              <label >Enter Principal Amount</label>
              <input
                type="text"
                className="PrincipalFixedDepo PrincipalCrypto"
                placeholder="Enter the amount in Dollars"
                value={principal}
                onChange={handlePrincipalChange}
              />
            </div>
              
              <div className="Box">
              <label>Choose your preferred duration</label>
                    <Slider
                      className="sliderrange"
                      min={0}
                      step={20}
                      max={12}
                      marks={marks}
                      onChange={setDuration}
                      value={duration}
                    />
              </div>
              
            </div>

            <button className="profitBtn" onClick={calculateProfit}>
              Calculate Returns
            </button>
          </div>
     
          <div className="outputHeader">
          <div className="greenLine"></div>
              <h2>Your Returns</h2>
              <div className="greenLine"></div>
            </div>
          <div className="outputWindow">
            
          <div className="outputBox">
            <span className="headerOutput">
            <BsCashCoin size={20}/>
              Principal Amount
            </span>
            <h1>${principal}</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <BiSolidTimeFive size={20}/>
              Duration
            </span>
            <h1>{duration} Months</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <GiProfit size={20}/>
              Matured Amount
            </span>
            <h1>${parseInt(profit) + parseInt(principal)}</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <FaPercentage size={20}/>
              Profit Percentage
            </span>
            <h1>Upto {profitRate}</h1>
          </div>
          </div>
        </div>
        <div data-scroll-section id="indigenous">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default FixedDepositPage;
