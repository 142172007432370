import React from 'react'
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
// import { Dropdown } from 'flowbite-react';
import { TbWorldCheck } from "react-icons/tb";
import { TbPhoneCall } from "react-icons/tb";
import { IoMdLocate } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import './Contact.css'
const Contact = () => {

    function SubmitFormContact(e){
        const formEle = document.querySelector("form");
        e.preventDefault();
        console.log("Submitted");

        const formData = new FormData(formEle);
        console.log(formData);
        fetch("https://script.google.com/macros/s/AKfycby5vlLQ2f3qO5-R9X1LvrD40HRloD7iAk1Z1S6HmpmD-q7JZVM9sdLX2AWj2jVgpPY6FA/exec", {
            method: "POST",
            body: formData,
        })

        window.location.reload();
    }

  return (
    <div className="contact-container">
      <div className="headerContact">
        <h1>CONTACT US</h1>
        <div className="greenLine"></div>
        <p>Reach Out, We're Here for You. </p>
      </div>
      <div className="contactDiv">
        <div className="contactLeft">
            <div className="leftSection">
                <div className="headerLeftContact">
                    <TbWorldCheck size={30} color="#279147"/>        
                    <h2>FOLLOW US</h2>
                </div>
                <div className="contentLeft">
                    <FaLinkedin size={25}/>
                    <FaInstagram size={25}/>
                    <FaXTwitter size={25}/>
                </div>
            </div>
            <div className="leftSection">
                <div className="headerLeftContact">
                <TbPhoneCall  size={30} color="#279147" />
                <h2>CONTACT</h2>
                </div>
                <div className="contentLeft">
                    <p> Kamal Ansari :  +91 77108 87238</p>
                </div>
            </div>
            <div className="leftSection">
                <div className="headerLeftContact">
                
                    <IoMdLocate size={30} color="#279147"/>  
                    <h2>LOCATE US HERE  </h2>
                </div>
                <div className="contentLeft">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.383753805275!2d72.82946027970948!3d19.17843403575767!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6f6ad069fb3%3A0xa7323844090ed808!2sKane%20Plaza%2C%20Near%20Kemp%20Plaza%2C%20Mindspace%2C%20Chincholi%20Bunder%20Rd%2C%20Malad%2C%20Ram%20Nagar%2C%20Malad%20West%2C%20Mumbai%2C%20Maharashtra%20400064!5e0!3m2!1sen!2sin!4v1704191643091!5m2!1sen!2sin" width="400" height="150"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <div className="leftSection">
                <div className="headerLeftContact">
                    <FaLocationDot size={30} color="#279147"/>       
                    <h2>ADDRESS</h2>
                </div>
                <div className="contentLeft">
                    <p>401, Kane Plaza, Near Subway, Chincholi Bunder, Malad(West), Mumbai - 400064</p>
                </div>
            </div>
        </div>
        <div className="contactRight">
            <div className="rightSection">
               
                <div className="container">
                    <form className="form" onSubmit={(e)=>SubmitFormContact(e)}>
                        <div className="gridForm">
                            <div className="input">
                                <input name="FirstName" required="" autocomplete="off" type="text"/>
                                <label for="FirstName">First Name</label>
                            </div>
                            <div className="input">
                                <input name="LastName" required="" autocomplete="off" type="text"/>
                                <label for="LastName">Last Name</label>
                            </div>
                            <div className="input">
                                <input name="Email" required="" autocomplete="off"  type="text"/>
                                <label for="Email">E-mail</label>
                            </div>
                            <div className="input">
                                <input name="Mobile" required="" autocomplete="off"  type="text"/>
                                <label for="Mobile">Mobile No</label>
                            </div>
                        </div>
                        <div className="messageBox">
                            <textarea name="Message" id="" cols="30" rows="5" ></textarea>
                            <label for="Message">Type your query</label>
                        </div>

                    
                        <button className='SendBtn'>Send message →</button>
                    </form>
                </div>

                
            </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
