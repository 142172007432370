import React, { useState } from "react";
import "./Faqs.css";

const Faqs = () => {
  const faqsData = [
    {
      question: "What is Seedify?",
      answer: "Seedify Investment Private Limited aims at building transparent investor portfolio for better clarity on the gains that occur.",
    },
    {
      question: "What is the minimum investment amount?",
      answer: "Minimum investment amount is $99.00/- only.",
    },
    {
      question: "How can I monitor the performance of my investments?",
      answer: "You are provided with weekly reports about your investment portfolio",
    },
    {
      question: "Are there any fees associated with investing?",
      answer: "No, there are no extra charges implied.",
    },
    {
      question: "Can I make changes to my investment portfolio?",
      answer: "Yes, you can make changes to your investment portfolio",
    },
    {
      question: "What is the process for withdrawing funds from my investment account?",
      answer: "There must be a 45-days prior notice issued by the invester to Seedify Investment Private Limited.",
    },
    {
      question: "What distinguishes your company from other investment firms?",
      answer: "Seedify Investment Private Limited aims at maintaining transparency, providing investor with monthly payouts.",
    },
    // {
    // question: "What is Seedify?",
    // answer: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. ...",
    // },
    // {
    // question: "What is Seedify?",
    // answer: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. ...",
    // },
    // Add more FAQ items as needed
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="faq-container">
      <div className="faqHeader">
        <h1>FAQs</h1>
        <div className="greenLine"></div>
        <p>Frequently Asked Questions.</p>
      </div>
      <div className="listFaqs">
        {faqsData.map((faq, index) => (
          <div
            className={`faqBox ${openIndex === index ? "open" : ""}`}
            key={index}
          >
            <div className="topFaqBox" onClick={() => handleToggle(index)}>
              <h2>{faq.question}</h2>
              <p style={{ color: openIndex === index ? "red" : "white" }}>
                <b>{openIndex === index ? "-" : "+"}</b>
              </p>
            </div>
            {openIndex === index && (
              <div
                className={`bottomFaqBox ${openIndex === index ? "open" : ""}`}
              >
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faqs;
