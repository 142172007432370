import React, { useState, useRef, useEffect } from "react";
import "./SIPPage.css";
import "../Hero/locomotive-scroll.css";
import LocomotiveScroll from "locomotive-scroll";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import Slider, { Range } from "rc-slider";

import { BsCashCoin } from "react-icons/bs";

import { BiSolidTimeFive } from "react-icons/bi";

import { GiProfit } from "react-icons/gi";

import { FaPercentage } from "react-icons/fa";
import "rc-slider/assets/index.css";
const imagesLoaded = require("imagesloaded");

const SIPPage = () => {
  const scrollRef = useRef(null);
  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");

    if (scrollContainer) {
      const scroll = new LocomotiveScroll({
        el: scrollContainer,
        smooth: true,
        getSpeed: true,
        getDirection: true,
        multiplier: 0.4, // Adjust the scroll speed here
      });

      scroll.update();

      imagesLoaded(scrollContainer, { background: true }, function () {
        scroll.update();
      });

      const resizeObserver = new ResizeObserver((entries) => {
        scroll.update();
      });

      resizeObserver.observe(document.body);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [principal, setPrincipal] = useState(""); // Set initial principal to "100"
  const [totalReturn, setTotalReturn] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [profitRate, setProfitRate] = useState("");
  const handleChange = (event) => {
    setPrincipal(event.target.value);
  };

  const calculateReturn = () => {
    const monthlyInterestRate = 0.02; // 2%
    const totalMonths = 12; // 12 months investment
    let principalAmount = parseFloat(principal); // Starting principal amount
    if(principalAmount<99){
      alert("Minimum Investment is $99.00/-");
    }
    else{
      let totalAmount = 0;

for (let i = 1; i <= totalMonths; i++) {
  const interest = principalAmount * monthlyInterestRate;
  totalAmount += principalAmount + interest;
  principalAmount = principalAmount + interest; // Incrementing principal by interest each month
}

const formattedTotalReturn = totalAmount.toFixed(3);
setTotalReturn(formattedTotalReturn);
    }
    
  };

  return (
    <>
      <NavBar />
      <div data-scroll-container ref={scrollRef}>
        <div className="content-container" data-scroll-section>
          <div className="headingContent">
            <h1>Welcome to Our Systematic Investment Plan (SIP) Opportunity</h1>
          </div>
          <div className="contentDiv">
            <span className="headerContentDiv">About</span>
            <p className="content">
              At SEEDIFY, we believe in the power of disciplined and systematic
              investing. That's why we're excited to offer our clients the
              opportunity to invest through Systematic Investment Plans (SIPs),
              a convenient and effective way to build wealth over time.
              <br />
              Explore our SIP Knowledge Hub, where you can access valuable
              information about Systematic Investment Plans. Whether you're new
              to investing or looking to optimize your investment strategy, our
              resources will guide you through the fundamentals of SIPs. From
              understanding how SIPs work to maximizing their potential
              benefits, we're here to empower you with the knowledge you need to
              make informed investment decisions.
            </p>
          </div>
          <div className="contentDiv">
            <span className="headerContentDiv">
              Transparent Portfolio Management
            </span>
            <p className="content">
              When you invest with SEEDIFY, transparency is paramount. Your
              funds will be invested in a diversified portfolio of assets,
              including investments facilitated through SIPs. We believe in
              providing our clients with full visibility into our investment
              strategies, performance, and fees. Through transparent reporting
              and open communication, we aim to earn your trust and confidence
              as your investment partner.
            </p>
          </div>
          <h2 className="chooseheader">WHY CHOOSE US?</h2>
          <div className="whychooseusDiv">
            <div className="contentDiv">
              <span className="headerContentDiv">Disciplined Investing</span>
              <p className="content">
                SIPs encourage disciplined investing by allowing you to invest a
                fixed amount regularly, regardless of market fluctuations.
              </p>
            </div>
            <div className="contentDiv">
              <span className="headerContentDiv">
                Long-Term Wealth Building
              </span>
              <p className="content">
                SIPs are well-suited for long-term wealth building, helping you
                achieve your financial goals through systematic and consistent
                investments.
              </p>
            </div>
            <div className="contentDiv">
              <span className="headerContentDiv">Diversification</span>
              <p className="content">
                Your SIP investments will be diversified across various asset
                classes within our portfolio, helping to mitigate risk and
                optimize returns.
              </p>
            </div>
            <div className="contentDiv">
              <span className="headerContentDiv">Transparent Reporting</span>
              <p className="content">
                You'll receive regular reports detailing the performance of your
                SIP investments, ensuring transparency and accountability.
              </p>
            </div>
          </div>
          {/* <p className="contentEnd">Ready to explore the potential of cryptocurrency investing with SEEDIFY <br/>Get started today by reaching out to our team. <br/>Whether you're looking to diversify your portfolio or capitalize on the growth of the crypto market, we're here to support you every step of the way.</p> */}
        </div>
        <div className="single-container" data-scroll-section>
        <p className="headercalc">CALCULATE YOUR RETURNS</p>
          <div className="inputForm">
            <div className="inputboxs">
            <div className="Box">
                    <label>Enter your principal amount</label>
              <input
                type="text"
                className="PrincipalCrypto"
                placeholder="Enter the amount in dollars"
                id="principalInput "
                value={principal}
                onChange={handleChange}
              />
              </div>
              <button className="profitBtn" onClick={calculateReturn}>
                Calculate Return
              </button>
            </div>

            {errorMessage && <p className="error">{errorMessage}</p>}
          </div>
          <div className="outputHeader">
          <div className="greenLine"></div>
              <h2>Your Returns</h2>
              <div className="greenLine"></div>
            </div>
          <div className="outputWindow">
            
          <div className="outputBox">
            <span className="headerOutput">
            <BsCashCoin size={20}/>
              Principal Amount
            </span>
            <h1>${12*principal}</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <BiSolidTimeFive size={20}/>
              Duration
            </span>
            <h1>12 Months</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <GiProfit size={20}/>
              Matured Amount
            </span>
            <h1>${parseFloat(totalReturn)}</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <FaPercentage size={20}/>
              Profit Percentage
            </span>
            <h1>Upto 02%</h1>
          </div>
          </div>
        </div>
        <div data-scroll-section id="indigenous">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default SIPPage;
