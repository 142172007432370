import React from "react";
import Logo from "../../Assets/SeedifyLogo.png";
import "./NavBar.css";
import { useNavigation } from "../../NavigationContext";
import { MdOutlineFileDownload } from "react-icons/md";
import { saveAs } from "file-saver";
import YourPdf from "../../Assets/pdfs/Seedify_Business_Proposal.pdf"; // Path to your PDF file

const NavBar = () => {
  const { scrollToSection } = useNavigation();
  const handleClick = () => {
    saveAs(YourPdf, "Seedify.pdf");
  };
  return (
    <div>
      <header>
        <nav className="menu--left" role="navigation">
          <div className="menuToggle">
            <input type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <ul className="menuItem">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#">About</a>
              </li>
              <li>
                <a href="#">Services</a>
              </li>
              <li>
                <a href="#">Testimonials</a>
              </li>
              <li>
                <a href="#">Our Team</a>
              </li>
              <li>
                <a href="#">FAQs</a>
              </li>
              <li>
                <a href="#">FAQs</a>
              </li>
            </ul>
          </div>
          <div className="logoDivtoggle">
            <img src={Logo} alt="" />
          </div>
        </nav>
        <nav>
          <div className="navFull glass">
            <div className="logoDiv">
              <img src={Logo} alt="" />
            </div>
            <ul className="navList">
              <li>
                <a onClick={() => scrollToSection("HomeSection")}>Home</a>
              </li>
              <li>
                <a onClick={() => scrollToSection("AboutSection")}>About</a>
              </li>
              <li>
                <a onClick={() => scrollToSection("ServiceSection")}>
                  Services
                </a>
              </li>
              <li>
                <a onClick={() => scrollToSection("TestiSection")}>
                  Testimonials
                </a>
              </li>
              <li>
                <a onClick={() => scrollToSection("TeamSection")}>Our Team</a>
              </li>
              <li>
                <a onClick={() => scrollToSection("FaqSection")}>FAQs</a>
              </li>
              <li>
                <a className="whiteBtn">
                  <b>WHY CHOOSE US?</b>
                </a>
              </li>
              <li>
                <a
                  className="greenBtn"
                  onClick={() => scrollToSection("ContactSection")}
                >
                  <b>CONTACT</b>
                </a>
              </li>
              <li>
                <a className="dwnbtn" onClick={handleClick}>
                  <b>
                    <MdOutlineFileDownload size={26} />
                  </b>
                </a>
              </li>
            </ul>
          </div>
        </nav>
        {/* <nav className="menu--right" role="navigation">
                <div className="menuToggle">
                <input type="checkbox"/>
                <span></span>
                <span></span>
                <span></span>
                <ul className="menuItem">
                    <li><a href="#">Home</a></li>
                    <li><a href="#">About</a></li>
                    <li><a href="#">Info</a></li>
                    <li><a href="#">Contact</a></li>
                    <li><a href="#">Show me more</a></li>
                </ul>
                </div>
            </nav> */}
      </header>
    </div>
  );
};

export default NavBar;
