import React, { useState, useRef, useEffect } from "react";
import "./Home.css";
import "./locomotive-scroll.css";
import { motion } from "framer-motion";
import Logo from "../../Assets/SeedifyLogo.png";
import Icon from "../../Assets/SeedifyIcon.png";
import NavBar from "../NavBar/NavBar";
import { Link } from "react-router-dom";
import Faqs from "../Faq/Faqs";
import DemoCarousel from "../Team/Team";
import Label from "../../Assets/label.png";
import FD from "../../Assets/fd.png";
import Forex from "../../Assets/iconforex.png";
import SIP from "../../Assets/sip.png";
import Testimonials from "../Testimonials/Testimonials";
import Team from "../Team/Team";
import MyServices from "../MyServices/MyServices";
import Contact from "../Contact/Contact";
import About from "../About/About";
import { FaArrowRight } from "react-icons/fa6";
import { SiBitcoin } from "react-icons/si";
import { MdLockPerson } from "react-icons/md";
import { IoMdSend } from "react-icons/io";
import { MdSavings } from "react-icons/md";
import LocomotiveScroll from "locomotive-scroll";
import Footer from "../Footer/Footer";
import spinwheel from "../../Assets/spinning-wheel.png";
import chatbot from "../../Assets/chatbot-icon.png";
import usericon from "../../Assets/usericon.png";
import { IoIosArrowDropdown, IoIosChatboxes } from "react-icons/io";
import stagezeroback from "../../Assets/stagezeroback.png";
import { fadeIn, textVariants } from "../../Motion";

import { useNavigation } from "../../NavigationContext";

const imagesLoaded = require("imagesloaded");
const chatData = [
  { question: "How are you?", answer: "I am fine, thank you!" },
  { question: "What is your name?", answer: "I am a chatbot." },
  {
    question: "What investment options do you offer?",
    answer:
      "We offer a range of investment options including fixed deposits, systematic investment plans (SIPs), Forex, and cryptocurrency investments.",
  },
  {
    question: "How can I start investing with your company?",
    answer:
      "To start investing with us, simply reach out to our team through the contact form on our website or give us a call at 7710887238.",
  },
  {
    question: "Can you provide more information about SIPs?",
    answer:
      "SIPs, or Systematic Investment Plans, allow investors to regularly invest a fixed amount. It's a convenient way to build wealth over time through disciplined investing.",
  },
  {
    question: "What is the minimum investment amount required?",
    answer:
      "The minimum investment amount varies depending on the investment option you choose. Please contact our team for specific details based on your preferences.",
  },
  {
    question: "How do you ensure transparency in your investment portfolio?",
    answer:
      "We ensure transparency through regular reporting and open communication with our clients.",
  },
  {
    question: "Can I diversify my investments with your company?",
    answer:
      "Absolutely! We encourage diversification to mitigate risk and optimize returns.",
  },
  {
    question: "Can I withdraw my investment at any time?",
    answer: "Withdrawal terms vary depending on the investment option.",
  },
  {
    question:
      "Do you offer any investment advice or financial planning services?",
    answer:
      "Yes, we offer investment advice and financial planning services to help you achieve your financial goals. Our team of financial experts can assist you in creating a personalized investment plan tailored to your needs and objectives.",
  },
];

const Home = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");

    if (scrollContainer) {
      const scroll = new LocomotiveScroll({
        el: scrollContainer,
        smooth: true,
        getSpeed: true,
        getDirection: true,
        multiplier: 0.4, // Adjust the scroll speed here
      });

      scroll.update();

      imagesLoaded(scrollContainer, { background: true }, function () {
        scroll.update();
      });

      const resizeObserver = new ResizeObserver((entries) => {
        scroll.update();
      });

      resizeObserver.observe(document.body);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const handleSuggestedQuestionClick = (question) => {
    setInput(question);
  };
  

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isChatStageOne, setChatStageOne] = useState(false);
  const [isChatOpen, setChatOpen] = useState(false);
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const handleChatStageOneClick = () => {
    // Show the popup when the "Spin Wheel" button is clicked
    setChatStageOne(true);
  };

  const handleSpinClick = () => {
    // Show the popup when the "Spin Wheel" button is clicked
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    // Close the popup when the close button is clicked
    setPopupOpen(false);
  };

  const handleChatClick = () => {
    // Show the popup when the "Spin Wheel" button is clicked
    setChatOpen(true);
  };
  const handleChatClose = () => {
    // Close the popup when the close button is clicked
    setChatOpen(false);
  };
  const handleInputChange = (e) => {
    setInput(e.target.value);
  };
  const handleSendMessage = () => {
    if (input.trim() === "") return;
    const token = localStorage.getItem("token");

    if (token && token === "ready") {
      setMessages([...messages, { text: input, isUser: true }]);
      setInput("");

      // Simulate backend processing (replace this with actual backend logic)
      const matchingQuestion = chatData.find(
        (item) => item.question.toLowerCase() === input.toLowerCase()
      );

      if (matchingQuestion) {
        setMessages([
          ...messages,
          {
            text: matchingQuestion.answer,
            query: matchingQuestion.question,
            isUser: false,
          },
        ]);
      } else {
        setMessages([
          ...messages,
          {
            text: "Our team will contact you soon.",
            query: input,
            isUser: false,
          },
        ]);
      }
    } else {
      setShowForm(!showForm);
    }
  };

  function SubmitForm(e) {
    const formEle = document.querySelector(".formChat");
    e.preventDefault();
    console.log("Submitted");

    const formData = new FormData(formEle);
    console.log(formData);
    fetch(
      "https://script.google.com/macros/s/AKfycbyvIQ5rJh7iUEpkyyJto-GKdoDN4W8EpvbG5rq_8XZWHsyq0yIN7fWFMGr1ckdQhqgTQQ/exec",
      {
        method: "POST",
        body: formData,
      }
    );
    localStorage.setItem("token", "ready");
    setShowForm(false);
    // window.location.reload();
  }

  return (
    <>
      <div className="fixedSideButtons">
        <div className="SideBtn">
          <div class="buttons">
        
          </div>
        </div>

        <div className="SideBtn">
          <div class="buttonss">
            <div class="btn-chatbot" onClick={handleChatClick}>
              <img src={chatbot} alt="" />
            </div>
          </div>
        </div>
      </div>
      {isChatOpen &&
        (!isChatStageOne ? (
          <motion.div
            animate={{ y: -50 }}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ ease: "easeOut", duration: 0.4 }}
            className="chatbotDiv"
          >
            <div className="headerChatDiv">
              <div className="headerChatBot">
                <div className="iconchatmessage">
                  <IoIosChatboxes size={35} color="#fff" />
                </div>
                <h3>Seedify Bot</h3>
              </div>

              <button className="btnClose" onClick={handleChatClose}>
                <IoIosArrowDropdown size={50} />
              </button>
            </div>
            <div className="bodyStagezero">
              <img src={stagezeroback} alt="" />
              <p className="greyme">No previous conversations</p>
              <button
                className="btnChatwithus"
                onClick={handleChatStageOneClick}
              >
                Chat Now
              </button>
            </div>
          </motion.div>
        ) : (
          <motion.div
            animate={{ y: -50 }}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ ease: "easeOut", duration: 0.4 }}
            className="chatbotDiv"
          >
            <div className="headerChatDiv">
              <div className="headerChatBot">
                <div className="iconchatmessage">
                  <IoIosChatboxes size={35} color="#fff" />
                </div>
                <h4>Leave a Message</h4>
              </div>

              <button className="btnClose" onClick={handleChatClose}>
                <IoIosArrowDropdown size={50} />
              </button>
            </div>
            <div className="chatbot">
              {showForm ? (
                <div className="FormChatBotStageOne">
                  <p>Help us know whom we are talking to</p>
                  <form className="formChat" onSubmit={(e) => SubmitForm(e)}>
                    <input
                      type="text"
                      name="Name"
                      className="inputTag"
                      placeholder="Enter Name"
                    />
                    <input
                      type="email"
                      name="Email"
                      className="inputTag"
                      placeholder="Enter Email ID"
                    />
                    <input
                      type="text"
                      name="Phone"
                      className="inputTag"
                      placeholder="Enter Phone Number"
                    />

                    <button className="sendMyDetail">Done</button>
                  </form>
                </div>
              ) : (
                <div className="messages">
                  {messages.map((message, index) => (
                    <div key={index} className="eachMsgChat">
                      <div className=" reverseflex">
                        <div className="iconSeed">
                          <img src={usericon} alt="" />
                        </div>
                        <div
                          className={
                            message.isUser ? "user-message" : "bot-message"
                          }
                        >
                          {message.query}
                          {/* {message.query} */}
                        </div>
                      </div>
                      <div className="flexme">
                        <div className="iconSeed">
                          <img src={Icon} alt="" />
                        </div>
                        <div
                          className={
                            message.isUser ? "user-message" : "bot-message"
                          }
                        >
                          {/* {message.text} */}
                          {message.text}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {!showForm && (<div className="suggestedDiv">
                <button
                  className="suggestedQues"
                  onClick={() =>
                    handleSuggestedQuestionClick(
                      "What investment options do you offer?"
                    )
                  }
                >
                  What investment options do you offer?
                </button>
                <button
                  className="suggestedQues"
                  onClick={() =>
                    handleSuggestedQuestionClick(
                      "How can I start investing with your company?"
                    )
                  }
                >
                  How can I start investing with your company?
                </button>
                <button
                  className="suggestedQues"
                  onClick={() =>
                    handleSuggestedQuestionClick(
                      "Can you provide more information about SIPs?"
                    )
                  }
                >
                  Can you provide more information about SIPs?
                </button>
                <button
                  className="suggestedQues"
                  onClick={() =>
                    handleSuggestedQuestionClick(
                      "What is the minimum investment amount required?"
                    )
                  }
                >
                  What is the minimum investment amount required?
                </button>
                <button
                  className="suggestedQues"
                  onClick={() =>
                    handleSuggestedQuestionClick(
                      "How do you ensure transparency in your investment portfolio?"
                    )
                  }
                >
                  How do you ensure transparency in your investment portfolio?
                </button>
                <button
                  className="suggestedQues"
                  onClick={() =>
                    handleSuggestedQuestionClick(
                      "Can I diversify my investments with your company?"
                    )
                  }
                >
                  Can I diversify my investments with your company?
                </button>
                <button
                  className="suggestedQues"
                  onClick={() =>
                    handleSuggestedQuestionClick(
                      "Can I withdraw my investment at any time?"
                    )
                  }
                >
                  Can I withdraw my investment at any time?
                </button>
                <button
                  className="suggestedQues"
                  onClick={() =>
                    handleSuggestedQuestionClick(
                      "Do you offer any investment advice or financial planning services?"
                    )
                  }
                >
                  Do you offer any investment advice or financial planning
                  services?
                </button>
              </div>)}

              <div className="input-container">
                <textarea
                  className="inputchatDiv"
                  type="text"
                  value={input}
                  onChange={handleInputChange}
                  placeholder="Type your question..."
                />
                <div className="submitDiv">
                  <button className="submitStage" onClick={handleSendMessage}>
                    Send <IoMdSend size={10} />
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      <div style={{ position: "relative" }}>
        <NavBar />

        <div data-scroll-container ref={scrollRef} className="badaDiv">
          <div id="HomeSection" className="heroSection" data-scroll-section>
            <div className="headersHero">
              <div className="mainHeader">
                <motion.p
                  // animate={{ y: -50 }}
                  variants={textVariants("left", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ ease: "easeOut", duration: 0.4 }}
                >
                  Supercharge your trading journey with{" "}
                  <FaArrowRight color="#279147" />{" "}
                  <b className="greenIt"> SEEDIFY</b>
                </motion.p>
              </div>
              <div className="subHeader">
                <p>Make your money work for you, not the other way around. </p>
                <br />
                <p className="smallme">Terms & Conditions Apply*</p>
              </div>
              <div className="knowMoreDiv">
                <button className="knowMorebtn">Know More</button>
              </div>
            </div>

            <div className="investDiv">
              <h2>
                Diversify your <b style={{ color: "#279147" }}>PORTFOLIO</b>{" "}
                now!{" "}
              </h2>
              <div className="domainsHero">
                <div className="domainBox ">
                  <div className="icon">
                    {/* <img src={FD} alt="" /> */}
                    <SiBitcoin size={50} />
                  </div>
                  <div className="iconHeader">
                    <h3>Crypto</h3>
                  </div>
                </div>
                <div className="domainBox ">
                  <div className="icon">
                    <img src={Forex} alt="" />
                  </div>
                  <div className="iconHeader">
                    <h3>Forex</h3>
                  </div>
                </div>
                <div className="domainBox ">
                  <div className="icon">
                    {/* <img src={FD} alt="" /> */}
                    <MdLockPerson size={50} />
                  </div>
                  <div className="iconHeader">
                    <h3>FD</h3>
                  </div>
                </div>
                <div className="domainBox ">
                  <div className="icon">
                  <img src={SIP} alt="" />
                  </div>
                  <div className="iconHeader">
                    <h3>SIP</h3>
                  </div>
                </div>
              </div>
              <Link to="/findReturns">
              <div className="investNowDiv">
                <button class="cssbuttons-io-button">
                  Get started
                  <div class="icon">
                    <svg
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </button>
              </div>
              </Link>
            </div>

            <div
              className="scroll-down-d indigenious_scroll"
              style={{ cursor: "pointer", zIndex: "3" }}
            >
              {/* <p className="text-center">Scroll for more</p> */}
              <div className="spinner scroll-down">
                <a href="#indigenous" data-scroll-to className="animate"></a>
              </div>
            </div>
          </div>
          <div data-scroll-section id="indigenous">
            <section id="AboutSection">
              <About />
            </section>

            <section id="ServiceSection">
              <MyServices />
            </section>
            <section id="TestiSection">
              <Testimonials />
            </section>
            <section id="TeamSection">
              <Team />
            </section>
            <section id="FaqSection">
              <Faqs />
            </section>
            <section id="ContactSection">
              <Contact />
            </section>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
