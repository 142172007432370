import React from "react";
import chart from "../../Assets/chart.jpg";
import comma from "../../Assets/comma.png";
import "./Testimonials.css";
import { FaStar } from "react-icons/fa";
import { CiStar } from "react-icons/ci";
import { useWindowSize } from "react-use";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";

const Testimonials = () => {
  return (
    <div className="TestiContainer">
      <div className="TestiLeftDiv">
        <div className="headerDiv">
          <h2>Read what they say about us</h2>
          <div className="greenLineDiv"></div>
        </div>
        <div className="paraDiv">
          <h3>Client's Words</h3>
          <p>
            "Seedify is more than an investment platform; it's a partner in my
            journey to financial prosperity."
          </p>
        </div>
        <div className="TestiCards">
          <Swiper
            spaceBetween={10}
            slidesPerView={2}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            modules={[Pagination, Navigation]}
            pagination={{
              type: "progressbar",
            }}
            breakpoints={{
              0: {
                spaceBetween: 0,
                slidesPerView: 1,
              },
              768: {
                spaceBetween: 10,
                slidesPerView: 2,
              },
              992: {
                spaceBetween: 10,
                slidesPerView: 2,
              },
              1280: {
                spaceBetween: 0,
                slidesPerView: 2,
              },
              1536: {
                spaceBetween: 10,
                slidesPerView: 2,
              },
              1600: {
                spaceBetween: 10,
                slidesPerView: 2,
              },
              1920: {
                spaceBetween: 10,
                slidesPerView: 2,
              },
              2250: {
                spaceBetween: 10,
                slidesPerView: 3,
              },
            }}
          >
            <SwiperSlide>
              <div class="testimonial-box-container">
                <div class="testimonial-box">
                  <div class="box-top">
                    <div class="profileTest">
                      <div class="profile-img">
                        <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                      </div>
                      <div class="name-user">
                        <strong>Anurag singh</strong>
                        <span>@anuragsingh</span>
                      </div>
                    </div>
                    <div class="reviews">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <CiStar />
                    </div>
                  </div>
                  <div class="client-comment">
                    <p>
                      "With Seedify, my investments are not just growing;
                      they're thriving."
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonial-box-container">
                <div class="testimonial-box">
                  <div class="box-top">
                    <div class="profileTest">
                      <div class="profile-img">
                        <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                      </div>
                      <div class="name-user">
                        <strong>Reena rawat</strong>
                        <span>@reena</span>
                      </div>
                    </div>
                    <div class="reviews">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                  <div class="client-comment">
                    <p>
                      "Seedify combines reliability with financial growth, a
                      winning combination for investors."
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonial-box-container">
                <div class="testimonial-box">
                  <div class="box-top">
                    <div class="profileTest">
                      <div class="profile-img">
                        <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                      </div>
                      <div class="name-user">
                        <strong>Kaif Siddiqui</strong>
                        <span>@kaif</span>
                      </div>
                    </div>
                    <div class="reviews">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <CiStar />
                    </div>
                  </div>
                  <div class="client-comment">
                    <p>
                      "With Seedify, my investments are not just numbers; they
                      are a pathway to financial success."
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonial-box-container">
                <div class="testimonial-box">
                  <div class="box-top">
                    <div class="profileTest">
                      <div class="profile-img">
                        <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                      </div>
                      <div class="name-user">
                        <strong>Komal shelar</strong>
                        <span>@komal</span>
                      </div>
                    </div>
                    <div class="reviews">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                  <div class="client-comment">
                    <p>
                      "Seedify's transparent approach and capital guarantees
                      instill confidence in every investment."
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonial-box-container">
                <div class="testimonial-box">
                  <div class="box-top">
                    <div class="profileTest">
                      <div class="profile-img">
                        <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                      </div>
                      <div class="name-user">
                        <strong>Govind yadav</strong>
                        <span>@govindyadav</span>
                      </div>
                    </div>
                    <div class="reviews">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      {/* <CiStar /> */}
                    </div>
                  </div>
                  <div class="client-comment">
                    <p>
                      "Seedify Investment delivers on promises – secure returns
                      and expert fund management."{" "}
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div class="testimonial-box-container">
                <div class="testimonial-box">
                  <div class="box-top">
                    <div class="profileTest">
                      <div class="profile-img">
                        <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                      </div>
                      <div class="name-user">
                        <strong>Asif khan</strong>
                        <span>@asif</span>
                      </div>
                    </div>
                    <div class="reviews">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <CiStar />
                    </div>
                  </div>
                  <div class="client-comment">
                    <p>
                      "Seedify's monthly profits and flexibility make investing
                      stress-free.".
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonial-box-container">
                <div class="testimonial-box">
                  <div class="box-top">
                    <div class="profileTest">
                      <div class="profile-img">
                        <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                      </div>
                      <div class="name-user">
                        <strong>Sandeep kumawat</strong>
                        <span>@sandeep</span>
                      </div>
                    </div>
                    <div class="reviews">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <CiStar />
                    </div>
                  </div>
                  <div class="client-comment">
                    <p>
                      "Seedify's tailored strategies have transformed my
                      financial landscape for the better."
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonial-box-container">
                <div class="testimonial-box">
                  <div class="box-top">
                    <div class="profileTest">
                      <div class="profile-img">
                        <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                      </div>
                      <div class="name-user">
                        <strong>Hariprasad gupta</strong>
                        <span>@hariprasad</span>
                      </div>
                    </div>
                    <div class="reviews">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <CiStar />
                    </div>
                  </div>
                  <div class="client-comment">
                    <p>
                      "Seedify's team ensures my financial goals are not just
                      met but exceeded."
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div class="testimonial-box-container">
                <div class="testimonial-box">
                  <div class="box-top">
                    <div class="profileTest">
                      <div class="profile-img">
                        <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                      </div>
                      <div class="name-user">
                        <strong>Fatima sami</strong>
                        <span>@fatimansami</span>
                      </div>
                    </div>
                    <div class="reviews">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                  <div class="client-comment">
                    <p>
                      "Choosing Seedify was a smart move; my returns speak for
                      themselves."
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div class="testimonial-box-container">
                <div class="testimonial-box">
                  <div class="box-top">
                    <div class="profileTest">
                      <div class="profile-img">
                        <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                      </div>
                      <div class="name-user">
                        <strong>Janvi talla</strong>
                        <span>@janvi</span>
                      </div>
                    </div>
                    <div class="reviews">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                  <div class="client-comment">
                    <p>
                      "Investing made easy – Seedify's secure returns and
                      user-friendly platform stand out."
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="TestiRightDiv">
        <img src={chart} alt="" className="chart" />
        <div className="iconinverted">
          <img src={comma} alt="" width={90} />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
