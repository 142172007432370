import { Route, Routes } from "react-router-dom";
import Home from "./Components/Hero/Home"
import './App.css'
import NavBar from "./Components/NavBar/NavBar";
import CryptoPage from "./Components/CryptoPage/CryptoPage";
import FixedDepositPage from "./Components/FixedDepositPage/FixedDepositPage";
import SIPPage from "./Components/SIPPage/SIPPage";
import { NavigationProvider } from "./NavigationContext";
import ForexPage from "./Components/ForexPage/ForexPage";
import AllCalculatorPage from "./Components/AllCalculatorPage/AllCalculatorPage";
function App() {
  return (
    <NavigationProvider>
      <div className="App">
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/crypto" element={<CryptoPage/>}></Route>
        <Route path="/forex" element={<ForexPage/>}></Route>
        <Route path="/fixedDeposit" element={<FixedDepositPage/>}></Route>
        <Route path="/SIP" element={<SIPPage/>}></Route>
        <Route path="/findReturns" element={<AllCalculatorPage/>}></Route>
      </Routes>
    </div>
    </NavigationProvider>
    
  );
}

export default App;