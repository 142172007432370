import React, { useState, useRef, useEffect } from "react";
import "./AllCalculatorPage.css";
import "./index.less";
import "../Hero/locomotive-scroll.css";
import LocomotiveScroll from "locomotive-scroll";
import NavBar from "../NavBar/NavBar";
import LunaBot1 from "../../Assets/lunabot1.png";
import LunaBot2 from "../../Assets/lunabot2.png";
import LunaBot3 from "../../Assets/lunabot3.png";
import LunaBot4 from "../../Assets/lunabot4.png";
import Appex from "../CircularProgressBar/Appex.jsx";
import Footer from "../Footer/Footer.jsx";
import Slider, { Range } from "rc-slider";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { BsCashCoin } from "react-icons/bs";

import { BiSolidTimeFive } from "react-icons/bi";

import { GiProfit } from "react-icons/gi";

import { FaPercentage } from "react-icons/fa";
import bot1 from "../../Assets/bot1.png";
import bot2 from "../../Assets/bot2.png";
import bot3 from "../../Assets/bot3.png";
import bot4 from "../../Assets/bot4.png";
import "rc-slider/assets/index.css";
const imagesLoaded = require("imagesloaded");

const AllCalculatorPage = () => {
  const scrollRef = useRef(null);
  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");

    if (scrollContainer) {
      const scroll = new LocomotiveScroll({
        el: scrollContainer,
        smooth: true,
        getSpeed: true,
        getDirection: true,
        multiplier: 0.4, // Adjust the scroll speed here
      });

      scroll.update();

      imagesLoaded(scrollContainer, { background: true }, function () {
        scroll.update();
      });

      const resizeObserver = new ResizeObserver((entries) => {
        scroll.update();
      });

      resizeObserver.observe(document.body);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // State to store selected LunaBot and input values
  const [selectedBot, setSelectedBot] = useState(null);
  const [principal, setPrincipal] = useState("");
  const [duration, setDuration] = useState(0);
  const [output, setOutput] = useState("0");
  const [profitRate, setProfitRate] = useState("");

  // Function to handle calculation based on selected bot
  const calculateProfit = () => {
    let minPrincipal;
    let maxProfitPercentage;

    switch (selectedBot) {
      case LunaBot1:
        minPrincipal = 2500;
        maxProfitPercentage = 0.02;
        setProfitRate("2%");
        break;
      case LunaBot2:
        minPrincipal = 6000;
        maxProfitPercentage = 0.03;
        setProfitRate("3%");
        break;
      case LunaBot3:
        minPrincipal = 12000;
        maxProfitPercentage = 0.04;
        setProfitRate("4%");
        break;
      case LunaBot4:
        minPrincipal = 30000;
        maxProfitPercentage = 0.05;
        setProfitRate("5%");
        break;
      default:
        return; // If no bot selected, do nothing
    }

    // Convert input values to numbers
    const principalAmount = parseFloat(principal);
    const durationMonths = parseFloat(duration);

    // Validate input
    if (isNaN(principalAmount) || isNaN(durationMonths)) {
      setOutput("Please enter valid input.");
      return;
    }

    if (principalAmount < minPrincipal) {
      alert(`Minimum principal for ${selectedBot} is ${minPrincipal}.`);
      return;
    }

    // Calculate profit
    const profit = principalAmount * maxProfitPercentage;
    setOutput(`$${profit.toFixed(2)}`);
  };

  const marks = {
    0: "24",
    0: "0",
    6: "6M",
    9: "9M",
    12: "12M",
    18: "18M",
    24: {
      style: {
        color: "red",
      },
      label: "24M",
    },
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [cryptoCalc, setCryptoCalc] = useState(true);
  const [fdCalc, setFdCalc] = useState(false);
  const [sipCalc, setSipCalc] = useState(false);
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (option == "Crypto") {
      setCryptoCalc(true);
      setFdCalc(false);
      setSipCalc(false);
    }

    if (option == "FD") {
        setCryptoCalc(false);
      setFdCalc(true);
      setSipCalc(false);
    }
    if (option == "SIP") {
        setCryptoCalc(false);
      setFdCalc(false);
      setSipCalc(true);
    }
  };


  const [fdprincipal, setfdPrincipal] = useState("");
  const [fdduration, setfdDuration] = useState("");
  const [fdprofit, setfdProfit] = useState(0);
  const[fdprofitRate, setfdProfitRate] = useState("")

  const handlePrincipalChange = (e) => {
    setfdPrincipal(e.target.value);
  };

  const calculatefdProfit = () => {
    const minInvestment = 10000;
    let rateOfInterest = 0;

    if (fdprincipal < minInvestment) {
      alert("Minimum investment amount is $10000");
      return;
    }

    switch (fdduration) {
      case 6:
        rateOfInterest = 0.25;
        setfdProfitRate("25%");
        break;
      case 9:
        rateOfInterest = 0.4;
        setfdProfitRate("40%");
        break;
      case 12:
        rateOfInterest = 0.6;
        setfdProfitRate("60%");
        break;
      default:
        alert("Please select a valid duration");
        return;
    }

    const interest = fdprincipal * rateOfInterest;
    setfdProfit(interest);
  };
  const fdmarks = {
  
    0: "0",
    6: "6M",
    9: "9M",
    
    
    12: {
      style: {
        color: "red",
      },
      label: "12M",
    },
  };



  const [spprincipal, setspPrincipal] = useState(""); // Set initial principal to "100"
  const [sptotalReturn, setspTotalReturn] = useState(0);
  const [sperrorMessage, setspErrorMessage] = useState("");
  const [spprofitRate, setspProfitRate] = useState("");
  const handleChange = (event) => {
    setspPrincipal(event.target.value);
  };

  const calculateReturn = () => {
    const monthlyInterestRate = 0.02; // 2%
    const totalMonths = 12; // 12 months investment
    let principalAmount = parseFloat(spprincipal); // Starting principal amount
    if(principalAmount<99){
      alert("Minimum Investment is $99.00/-");
    }
    else{
      let totalAmount = 0;

for (let i = 1; i <= totalMonths; i++) {
  const interest = principalAmount * monthlyInterestRate;
  totalAmount += principalAmount + interest;
  principalAmount = principalAmount + interest; // Incrementing principal by interest each month
}

const formattedTotalReturn = totalAmount.toFixed(3);
setspTotalReturn(formattedTotalReturn);
    }
    
  };

  return (
    <>
      <NavBar />
      <div className="serviceDivBig" data-scroll-container ref={scrollRef}>
        <div className="content-container" data-scroll-section>
          <div className="headingContentAll">
            <h1>Select your investment plan</h1>
            <div className="optionsPlan">
              <div
                className={`optionDiv ${
                  selectedOption === "Crypto" ? "selected" : ""
                }`}
                onClick={() => handleOptionClick("Crypto")}
              >
                Crypto
              </div>
              <div
                className={`optionDiv ${
                  selectedOption === "FD" ? "selected" : ""
                }`}
                onClick={() => handleOptionClick("FD")}
              >
                FD
              </div>
              <div
                className={`optionDiv ${
                  selectedOption === "SIP" ? "selected" : ""
                }`}
                onClick={() => handleOptionClick("SIP")}
              >
                SIP
              </div>
            </div>
          </div>
          <div className="CalcWindow">
            {cryptoCalc && (
                <div className="bots">
                <div className="listingbots">
              <img
                  src={LunaBot1}
                  alt=""
                  className="lunabot"
                  data-tip data-tooltip-id="lunabot1Tip"
                  onClick={() => setSelectedBot(LunaBot1)}
                />
                
                <ReactTooltip id="lunabot1Tip" place="bottom" effect="solid">
                Minimum Investment - $2,500/-
                </ReactTooltip>
                <img
                  src={LunaBot2}
                  alt=""
                  className="lunabot"
                  data-tip data-tooltip-id="lunabot2Tip"
                  onClick={() => setSelectedBot(LunaBot2)}
                />
                <ReactTooltip id="lunabot2Tip" place="bottom" effect="solid">
                Minimum Investment - $6,000/-
                </ReactTooltip>
                <img
                  src={LunaBot3}
                  alt=""
                  className="lunabot"
                  data-tip data-tooltip-id="lunabot3Tip"
                  onClick={() => setSelectedBot(LunaBot3)}
                />
                <ReactTooltip id="lunabot3Tip" place="bottom" effect="solid">
                Minimum Investment - $12,000/-
                </ReactTooltip>
                <img
                  src={LunaBot4}
                  alt=""
                  className="lunabot"
                  data-tip data-tooltip-id="lunabot4Tip"
                  onClick={() => setSelectedBot(LunaBot4)}
                />
                <ReactTooltip id="lunabot4Tip" place="bottom" effect="solid">
                Minimum Investment - $30,000/-
                </ReactTooltip>
              </div>
                <div className="cryptoCalcBox">
                  <div className="Box">
                    <label>Enter your principal amount</label>
                    <input
                      type="text"
                      className="PrincipalCrypto"
                      placeholder="Enter the amount in Dollars"
                      value={principal}
                      onChange={(e) => setPrincipal(e.target.value)}
                    />
                  </div>
                  <div className="Box">
                    <label>Choose your preferred duration</label>
                    <Slider
                      className="sliderrange"
                      min={0}
                      step={20}
                      max={24}
                      marks={marks}
                      onChange={setDuration}
                      value={duration}
                    />
                  </div>
                  <div className="Box">
                    <button className="profitBtn" onClick={calculateProfit}>
                      Calculate Returns
                    </button>
                  </div>
                </div>
                
          <div className="outputHeader">
          <div className="greenLine"></div>
              <h2>Your Returns</h2>
              <div className="greenLine"></div>
            </div>
          <div className="outputWindow">
            
          <div className="outputBox">
            <span className="headerOutput">
            <BsCashCoin size={20}/>
              Principal Amount
            </span>
            <h1>${principal}</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <BiSolidTimeFive size={20}/>
              Duration
            </span>
            <h1>{duration} Months</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <GiProfit size={20}/>
              Profit per Month
            </span>
            <h1>{output}</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <FaPercentage size={20}/>
              Profit Percentage
            </span>
            <h1>Upto {profitRate}</h1>
          </div>
          </div>
          </div>
            )}
            {fdCalc && (
                <div className="single-container" >
        <p className="headercalc">CALCULATE YOUR RETURNS</p>
          <div className="inputForm">
            <div className="inputboxs">
            <div className="Box">
              <label >Enter Principal Amount</label>
              <input
                type="text"
                className="PrincipalFixedDepo PrincipalCrypto"
                placeholder="Enter the amount in Dollars"
                value={fdprincipal}
                onChange={handlePrincipalChange}
              />
            </div>
              
              <div className="Box">
              <label>Choose your preferred duration</label>
                    <Slider
                      className="sliderrange"
                      min={0}
                      step={20}
                      max={12}
                      marks={fdmarks}
                      onChange={setfdDuration}
                      value={fdduration}
                    />
              </div>
              
            </div>

            <button className="profitBtn" onClick={calculatefdProfit}>
              Calculate Returns
            </button>
          </div>
     
          <div className="outputHeader">
          <div className="greenLine"></div>
              <h2>Your Returns</h2>
              <div className="greenLine"></div>
            </div>
          <div className="outputWindow">
            
          <div className="outputBox">
            <span className="headerOutput">
            <BsCashCoin size={20}/>
              Principal Amount
            </span>
            <h1>${fdprincipal}</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <BiSolidTimeFive size={20}/>
              Duration
            </span>
            <h1>{fdduration} Months</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <GiProfit size={20}/>
              Matured Amount
            </span>
            <h1>${parseInt(fdprofit) + parseInt(fdprincipal)}</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <FaPercentage size={20}/>
              Profit Percentage
            </span>
            <h1>Upto {fdprofitRate}</h1>
          </div>
          </div>
        </div>
            )}
            {sipCalc && (
                <div className="single-container" >
        <p className="headercalc">CALCULATE YOUR RETURNS</p>
          <div className="inputForm">
            <div className="inputboxs">
            <div className="Box">
                    <label>Enter your principal amount</label>
              <input
                type="text"
                className="PrincipalCrypto"
                placeholder="Enter the amount in dollars"
                id="principalInput "
                value={spprincipal}
                onChange={handleChange}
              />
              </div>
              <button className="profitBtn" onClick={calculateReturn}>
                Calculate Return
              </button>
            </div>

            {sperrorMessage && <p className="error">{sperrorMessage}</p>}
          </div>
          <div className="outputHeader">
          <div className="greenLine"></div>
              <h2>Your Returns</h2>
              <div className="greenLine"></div>
            </div>
          <div className="outputWindow">
            
          <div className="outputBox">
            <span className="headerOutput">
            <BsCashCoin size={20}/>
              Principal Amount
            </span>
            <h1>${12*spprincipal}</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <BiSolidTimeFive size={20}/>
              Duration
            </span>
            <h1>12 Months</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <GiProfit size={20}/>
              Matured Amount
            </span>
            <h1>${parseFloat(sptotalReturn)}</h1>
          </div>
          <div className="outputBox">
            <span className="headerOutput">
            <FaPercentage size={20}/>
              Profit Percentage
            </span>
            <h1>Upto 02%</h1>
          </div>
          </div>
        </div>
            )}
          </div>
          {/* <p className="contentEnd">Ready to explore the potential of cryptocurrency investing with SEEDIFY <br/>Get started today by reaching out to our team. <br/>Whether you're looking to diversify your portfolio or capitalize on the growth of the crypto market, we're here to support you every step of the way.</p> */}
        </div>

        <div data-scroll-section id="indigenous">
          <Footer />
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default AllCalculatorPage;
