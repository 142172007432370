import React, { useState, useRef, useEffect } from "react";
import "./ForexPage.css";
import "./index.less";
import "../Hero/locomotive-scroll.css";
import LocomotiveScroll from "locomotive-scroll";
import NavBar from "../NavBar/NavBar";
import LunaBot1 from "../../Assets/lunabot1.png";
import LunaBot2 from "../../Assets/lunabot2.png";
import LunaBot3 from "../../Assets/lunabot3.png";
import LunaBot4 from "../../Assets/lunabot4.png";
import Appex from "../CircularProgressBar/Appex.jsx";
import Footer from "../Footer/Footer.jsx";
import Slider, { Range } from "rc-slider";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { BsCashCoin } from "react-icons/bs";

import { BiSolidTimeFive } from "react-icons/bi";

import { GiProfit } from "react-icons/gi";

import { FaPercentage } from "react-icons/fa";
import bot1 from "../../Assets/bot1.png";
import bot2 from "../../Assets/bot2.png";
import bot3 from "../../Assets/bot3.png";
import bot4 from "../../Assets/bot4.png";
import "rc-slider/assets/index.css";
const imagesLoaded = require("imagesloaded");

const ForexPage = () => {
  const scrollRef = useRef(null);
  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");

    if (scrollContainer) {
      const scroll = new LocomotiveScroll({
        el: scrollContainer,
        smooth: true,
        getSpeed: true,
        getDirection: true,
        multiplier: 0.4, // Adjust the scroll speed here
      });

      scroll.update();

      imagesLoaded(scrollContainer, { background: true }, function () {
        scroll.update();
      });

      const resizeObserver = new ResizeObserver((entries) => {
        scroll.update();
      });

      resizeObserver.observe(document.body);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // State to store selected LunaBot and input values
  const [selectedBot, setSelectedBot] = useState(null);
  const [principal, setPrincipal] = useState("");
  const [duration, setDuration] = useState(0);
  const [output, setOutput] = useState("0");
  const [profitRate, setProfitRate] = useState("");

  // Function to handle calculation based on selected bot
  const calculateProfit = () => {
    let minPrincipal;
    let maxProfitPercentage;

    switch (selectedBot) {
      case LunaBot1:
        minPrincipal = 2500;
        maxProfitPercentage = 0.02;
        setProfitRate("2%");
        break;
      case LunaBot2:
        minPrincipal = 6000;
        maxProfitPercentage = 0.03;
        setProfitRate("3%");
        break;
      case LunaBot3:
        minPrincipal = 12000;
        maxProfitPercentage = 0.04;
        setProfitRate("4%");
        break;
      case LunaBot4:
        minPrincipal = 30000;
        maxProfitPercentage = 0.05;
        setProfitRate("5%");
        break;
      default:
        return; // If no bot selected, do nothing
    }

    // Convert input values to numbers
    const principalAmount = parseFloat(principal);
    const durationMonths = parseFloat(duration);

    // Validate input
    if (isNaN(principalAmount) || isNaN(durationMonths)) {
      setOutput("Please enter valid input.");
      return;
    }

    if (principalAmount < minPrincipal) {
      alert(`Minimum principal for ${selectedBot} is ${minPrincipal}.`);
      return;
    }

    // Calculate profit
    const profit = principalAmount * maxProfitPercentage;
    setOutput(`$${profit.toFixed(2)}`);
  };

  const marks = {
    0: "24",
    0: "0",
    6: "6M",
    9: "9M",
    12: "12M",
    18: "18M",
    24: {
      style: {
        color: "red",
      },
      label: "24M",
    },
  };

  return (
    <>
      <NavBar />
      <div className="serviceDivBig" data-scroll-container ref={scrollRef}>
        <div className="content-container" data-scroll-section>
          <div className="headingContent">
            <h1>Welcome to Our Forex Investment Opportunity</h1>
          </div>
          <div className="contentDiv">
            <span className="headerContentDiv">About</span>
            <p className="content">
              At SEEDIFY, we recognize the potential of forex trading as a
              powerful vehicle for wealth generation. With the foreign exchange
              market being the largest financial market in the world, we are
              excited to offer our clients the opportunity to participate in
              this dynamic arena. Explore our Forex Knowledge Hub, where you can
              access valuable resources and insights into the world of forex
              trading. Whether you're a novice trader or an experienced
              investor, our educational materials will help you navigate the
              complexities of the forex market. From understanding currency
              pairs to mastering technical analysis, we're here to empower you
              with the knowledge you need to succeed.
              <br />
              Explore our Crypto Knowledge Hub, where you can gain insights into
              the world of cryptocurrency investing. Whether you're a seasoned
              investor or new to the crypto space, our resources will help you
              navigate this exciting asset class. From understanding blockchain
              technology to analyzing market trends, we're here to empower you
              with the knowledge you need to make informed investment decisions.
            </p>
          </div>
          <div className="contentDiv">
            <span className="headerContentDiv">
              Profit Sharing with Capital Guarantee
            </span>
            <p className="content">
              Our profit-sharing model ensures that you benefit directly from
              the success of your investment. We offer a 50/50 profit-sharing
              arrangement, providing you with a fair and transparent
              distribution of profits generated from forex trading activities.
              Additionally, we guarantee the safety of your initial capital,
              giving you peace of mind while pursuing potential returns in the
              forex market.
            </p>
          </div>
          <div className="contentDiv">
            <span className="headerContentDiv">
              Transparent Portfolio Management
            </span>
            <p className="content">
              When you invest with SEEDIFY, transparency is our guiding
              principle. Your funds will be allocated to a diversified portfolio
              of assets, which may include forex trading opportunities. We
              believe in providing our clients with full visibility into our
              investment strategies, performance, and fees. Through transparent
              reporting and open communication, we aim to earn your trust and
              confidence as your investment partner.
            </p>
          </div>
          <h2 className="chooseheader">WHY CHOOSE US?</h2>
          <div className="whychooseusDiv">
            <div className="contentDiv">
              <span className="headerContentDiv">Expert Guidance</span>
              <p className="content">
                Our team of forex trading professionals has extensive experience
                in navigating the forex market and identifying profitable
                opportunities.
              </p>
            </div>
            <div className="contentDiv">
              <span className="headerContentDiv">Diversified Portfolio</span>
              <p className="content">
                We employ a diversified investment approach to mitigate risk and
                maximize returns for our clients, including exposure to forex
                markets.
              </p>
            </div>
            <div className="contentDiv">
              <span className="headerContentDiv">Transparent Reporting</span>
              <p className="content">
                You'll receive regular reports detailing your investment
                portfolio's performance, including forex trading activities and
                outcomes.
              </p>
            </div>
            <div className="contentDiv">
              <span className="headerContentDiv">Client-Centric Approach</span>
              <p className="content">
                Your financial goals and preferences are our top priority. We
                tailor our investment solutions to align with your individual
                objectives and risk tolerance.
              </p>
            </div>
          </div>
          {/* <p className="contentEnd">Ready to explore the potential of cryptocurrency investing with SEEDIFY <br/>Get started today by reaching out to our team. <br/>Whether you're looking to diversify your portfolio or capitalize on the growth of the crypto market, we're here to support you every step of the way.</p> */}
        </div>

        <div data-scroll-section id="indigenous">
          <Footer />
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default ForexPage;
